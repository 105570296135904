import React from 'react';
import { Link } from 'react-router-dom';
import thumb from '../../assets/images/webapp/4.png';

function WebappForth({ className }) {
  return (
    <section
      className={`appie-traffic-area pt-140 pb-180 ${className || ''}`}
      style={{ background: '#eef1f6' }}
    >
      <div className="container d-md-flex align-items-center">
        <div className="row col-md-4">
          <div className="">
            <div className="appie-traffic-title " style={{ paddingRight: 20 }}>
              <span>Discover Positions</span>
              <h3 className="title line-height-md mb-10">
                Find Your Opportunities
              </h3>
              <p className="pr-md-20">
                Discover fully funded opportunities efficiently, without
                time-consuming searches across multiple university websites and
                professors' social media profiles.
              </p>
              <p className="pr-md-20 pt-10">
                Be early applicant by daily new positions Alert!
              </p>
              <span className="mt-2" style={{ fontSize: 16 }}>
                <Link
                  className="primary-color font-weight-bold "
                  to="/positions"
                >
                  Learn More
                </Link>
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-8  ">
          <div
            className="shadow-xl  overflow-hidden rounded-xl"
            style={{ padding: '5px 0 4px 1px', backgroundColor: '#fff' }}
          >
            <img
              width={1100}
              className="wow animated fadeInRight rounded-lg"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
              src={thumb}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebappForth;
