import React from 'react';

function HeroService() {
  return (
    <>
      <div className="appie-page-title-area appie-page-service-title-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-page-title-item" style={{ marginTop: 0 }}>
                <span>Over 1000 Daily Positions</span>
                <h3 className="title">
                  Stay Ahead in the PhD Race: <br /> Introducing Our Position
                  Service
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroService;
