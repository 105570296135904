import React from 'react';
import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
import Drawer from '../Mobile/Drawer';
import FaqHomeOne from './FaqHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import PricingHomeOne from './PricingHomeOne';
import ProjectHomeOne from './ProjectHomeOne';
// import TrafficHomeOne from './TrafficHomeOne';
import WebappOne from './WebappOne';
import WebappSecond from './WebappSecond';
import WebappThird from './WebappThird';
import WebappForth from './WebappForth';

function HomeOne() {
  const [drawer, drawerAction] = useToggle(false);

  return (
    <>
      <Drawer drawer={drawer} action={drawerAction.toggle} />
      <HomeOneHeader action={drawerAction.toggle} />
      <HeroHomeOne />
      {/* <ServicesHomeOne /> */}
      {/* <FeaturesHomeOne /> */}
      {/* <TrafficHomeOne /> */}
      <WebappOne />
      <WebappForth />
      <WebappSecond />
      <WebappThird />
      {/* <TestimonialHomeOne /> */}
      {/* <TeamHomeOne /> */}
      <PricingHomeOne />
      <FaqHomeOne />
      {/* <BlogHomeOne /> */}
      <ProjectHomeOne />
      <FooterHomeOne />
      <BackToTop />
    </>
  );
}

export default HomeOne;
