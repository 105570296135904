import React, { useEffect, useState } from 'react';

function PricingHomeOne({ className }) {
  const [toggleSwitch, setSwitchValue] = useState(true);
  const [prices, setPrices] = useState([
    { total: 9.99, monthly: 3.3 },
    { total: 29.99, monthly: 2.5 },
  ]);
  useEffect(() => {
    if (toggleSwitch) {
      setPrices([
        { total: 9.99, monthly: 3.3, symbol: '£' },
        { total: 29.99, monthly: 2.5, symbol: '£' },
      ]);
    } else {
      setPrices([
        { total: '599,000', monthly: '199,000', symbol: 'T' },
        { total: '1,499,000', monthly: '125,000', symbol: 'T' },
      ]);
    }
  }, [toggleSwitch]);

  return (
    <>
      <section
        className={`appie-pricing-area pt-90 pb-90 ${className || ''} `}
        style={{ background: 'white' }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Choose your plan</h3>
                {/* <p>Prices in IRR also are available &nbsp;</p>
                <a href="https://t.me/ApplyKite">here</a> */}
              </div>
              <ul
                className="list-inline text-center switch-toggler-list"
                role="tablist"
                id="switch-toggle-tab"
              >
                <li className={`month ${toggleSwitch ? 'active' : ''}`}>
                  <a href="#">GBP</a>
                </li>
                <li>
                  <label
                    onClick={() => setSwitchValue(!toggleSwitch)}
                    className={`switch ${toggleSwitch ? 'on' : 'off'}`}
                  >
                    <span className="slider round" />
                  </label>
                </li>
                <li
                  className={`year ${toggleSwitch === false ? 'active' : ''}`}
                >
                  <a href="#">IRR</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tabed-content">
            <div id="month">
              <div className="row justify-content-center">
                {/* PREMIUM */}
                <div className="col-xl-4 col-md-6 wow animated fadeInUp">
                  <div
                    className="pricing-one__single"
                    style={{ border: '2px solid #f8452d' }}
                  >
                    <div className="pricig-heading">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="price-range">
                          <sup>{prices[0].symbol}</sup>{' '}
                          <span style={{ fontSize: 37 }}>
                            {prices[0].total}
                          </span>
                        </div>
                        <div
                          style={{ fontSize: 18 }}
                          className="price-range text-orange"
                        >
                          3 Months
                        </div>
                      </div>
                      <div className="d-flex ">
                        <span style={{ fontSize: 14 }} className="mr-1">
                          {prices[0].symbol}
                        </span>{' '}
                        <span
                          className="mr-1 font-weight-bold "
                          style={{ fontSize: 17 }}
                        >
                          {prices[0].monthly}
                        </span>
                        <p>/month</p>
                      </div>
                    </div>
                    <div className="pricig-body">
                      <ul>
                        <li className="d-flex">
                          <i
                            className="fal fa-check mr-2 "
                            style={{ minWidth: 25 }}
                          />
                          <span>
                            Access to fully funded positions published on
                            university websites, position websites, and hidden
                            <span className="text-orange mr-1">
                              professors’ social networks.
                            </span>
                          </span>
                        </li>
                        <li className="d-flex">
                          <i
                            className="fa fa-bullhorn mr-2"
                            style={{ minWidth: 25 }}
                          />
                          Instant notification of opened positions
                        </li>
                        <li className="d-flex">
                          <i
                            className="fa fa-magic mr-2"
                            style={{ minWidth: 25 }}
                          />
                          <span>
                            <span className="text-orange mr-1">30</span>
                            personalized emails to contact potential supervisors
                          </span>
                        </li>
                        <li className="d-flex">
                          <i
                            className="fa fa-magic mr-2"
                            style={{ minWidth: 25 }}
                          />
                          <span>
                            <span className="text-orange mr-1">30</span>
                            SOP and CV, tailored to specific position
                            requirements
                          </span>
                        </li>
                        <li
                          className="d-flex  "
                          style={{ visibility: 'hidden' }}
                        >
                          <i
                            className="fa fa-bolt mr-2"
                            style={{ minWidth: 25 }}
                          />
                          <span className="text-purple ">
                            LIST OF PROFESSORS aligned with your field, those
                            with recent funding or open positions based on
                            +500,000 records
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-rebon">
                      <span>PREMIUM</span>
                    </div>
                  </div>
                </div>
                {/* PRO */}
                <div className="col-xl-4 col-md-6 wow animated fadeInRight">
                  <div
                    className="pricing-one__single"
                    style={{ border: '2px solid #9b2cfa' }}
                  >
                    <div className="pricig-heading">
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="price-range">
                          <sup>{prices[1].symbol}</sup>{' '}
                          <span style={{ fontSize: 37 }}>
                            {prices[1].total}
                          </span>
                        </div>
                        <div
                          className="price-range text-purple"
                          style={{ fontSize: 18 }}
                        >
                          12 Months
                        </div>
                      </div>
                      <div className="d-flex ">
                        <span style={{ fontSize: 14 }} className="mr-1">
                          {prices[1].symbol}
                        </span>{' '}
                        <span
                          className="mr-1 font-weight-bold "
                          style={{ fontSize: 17 }}
                        >
                          {prices[1].monthly}
                        </span>
                        <p>/month</p>
                      </div>
                    </div>
                    <div className="pricig-body">
                      <ul>
                        <li className="d-flex">
                          <i
                            className="fal fa-check mr-2 "
                            style={{ minWidth: 25 }}
                          />
                          <span>
                            Access to fully funded positions published on
                            university websites, position websites, and hidden
                            <span className="text-purple mr-1">
                              professors’ social networks.
                            </span>
                          </span>
                        </li>
                        <li className="d-flex">
                          <i
                            className="fa fa-bullhorn mr-2"
                            style={{ minWidth: 25 }}
                          />
                          Instant notification of opened positions
                        </li>
                        <li className="d-flex">
                          <i
                            className="fa fa-magic mr-2"
                            style={{ minWidth: 25 }}
                          />

                          <span>
                            <span className="text-purple mr-1">Unlimited</span>
                            personalized emails to contact potential supervisors
                          </span>
                        </li>
                        <li className="d-flex">
                          <i
                            className="fa fa-magic mr-2"
                            style={{ minWidth: 25 }}
                          />
                          <span>
                            <span className="text-purple mr-1">Unlimited</span>
                            SOP and CV, tailored to specific position
                            requirements
                          </span>
                        </li>
                        <li className="d-flex ">
                          <i
                            className="fa fa-bolt mr-2"
                            style={{ minWidth: 25 }}
                          />
                          <span className="text-purple ">
                            LIST OF PROFESSORS aligned with your field, those
                            with recent funding or open positions based on
                            +500,000 records
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-rebon bg-purple">
                      <span>PRO</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingHomeOne;
