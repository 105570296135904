import React from 'react';

function HeroService() {
  return (
    <>
      <div className="appie-page-title-area appie-page-service-title-area_supervisor">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-page-title-item" style={{ marginTop: 0 }}>
                <span>Over 2.5 Million Supervisors</span>
                <h3 className="title">
                  Introducing Our Supervisor Service: <br />
                  Your Gateway to <br /> Securing a Funded PhD Position
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroService;
