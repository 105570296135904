import React from 'react';
// import thumb from '../../assets/images/service-details-thumb_super.png';
import profileImg from '../../assets/images/supervisors/1.webp';
import sidebarImg from '../../assets/images/supervisors/2.webp';
import filterImg from '../../assets/images/supervisors/3.webp';
import ssaImg from '../../assets/images/supervisors/4.webp';
import collaboratorsImg from '../../assets/images/supervisors/5.webp';
import actionImg from '../../assets/images/supervisors/6.webp';

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
              <div className="service-details-sidebar mr-50">
                <div className="service-category-widget">
                  <ul>
                    <li>
                      <i className="fal fa-user-friends"></i>Over 2.5M
                      Professors
                    </li>
                    <li>
                      <i className="fal fa-tag"></i>Globally Available
                    </li>
                    <li>
                      <i className="fal fa-home"></i>Access to Related articles
                    </li>
                    <li>
                      <i className="fal fa-bookmark"></i>Distinguish Grant
                      Holders
                    </li>
                    <li>
                      <i className="fal fa-globe"></i>Access to Academic Page
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-lg-12">
              <div className="service-details-content">
                {/* <div className="row align-items-center">
                  <div className="thumb col-lg-8 ">
                    <img width={600} src={thumb} alt="supervisors" />
                  </div>
                  <div className="col-lg-4">
                    <div className="service-details-sidebar ">
                      <div
                        className="service-category-widget"
                        style={{ marginBottom: 0 }}
                      >
                        <ul>
                          <li>
                            <i className="fal fa-user-friends"></i>Over 2.5M
                            Professors
                          </li>
                          <li>
                            <i className="fal fa-tag"></i>Globally Available
                          </li>
                          <li>
                            <i className="fal fa-home"></i>Access to Related
                            articles
                          </li>
                          <li>
                            <i className="fal fa-bookmark"></i>Distinguish Grant
                            Holders
                          </li>
                          <li>
                            <i className="fal fa-globe"></i>Access to Academic
                            Page
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="content">
                  {/* <h3 className="title">What we're Building</h3> */}
                  <p>
                    Finding the <b>"right supervisor"</b> is a crucial step in
                    your journey to securing a funded PhD position. With over
                    <b> 2.5 million PhD supervisors</b> from around the globe,
                    <b> half a million</b> of whom are already available in our
                    system and waiting for your message, our platform offers the
                    most comprehensive and effortless way to connect with
                    potential supervisors. This powerful tool gives you easy
                    access to academic leaders in your field, providing you with
                    the insider knowledge and opportunities you need to succeed.
                  </p>
                  <span className="primary-color font-weight-bold">
                    <i className="fal fa-check " style={{ minWidth: 25 }} /> Why
                    Do You Need Our Supervisor Service?
                  </span>
                  <p>
                    As a student aspiring to become a researcher and pursue
                    higher education, finding the best PhD supervisor can be
                    challenging. Having faced similar struggles, the founders of
                    Kite designed the supervisor service to offer exceptional
                    benefits:
                  </p>
                  <ul className="mt-20">
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Global Reach and Academic Insight
                      </h5>
                      <p>
                        Our supervisor service connects you with professors
                        worldwide, allowing you to explore a vast array of
                        <b> research opportunities</b>. By accessing related
                        articles, academic pages, and PhD supervisors’ LinkedIn
                        profiles directly through our platform, you can stay
                        updated on the latest developments in your area of
                        interest. This keeps you informed and allows you to
                        engage meaningfully with the work of potential
                        supervisors, making you a more attractive candidate.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Funding Opportunities (Supervisors with Grants)
                      </h5>
                      <p>
                        One of the standout features of our platform is the
                        ability to identify supervisors who have{' '}
                        <b>recently secured research grants</b>. These PhD
                        supervisors are often looking for motivated students to
                        join their research teams. By aligning your interests
                        with theirs and demonstrating your knowledge of their
                        work, you can effectively showcase your suitability for
                        their projects, significantly increasing your chances of
                        securing a <b>funded PhD position</b>.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Tailored CV and Email for Each Supervisor
                      </h5>
                      <p>
                        Our tool goes beyond simply connecting you with
                        potential PhD supervisors; it also helps you make the
                        best possible first impression. With our{' '}
                        <b>Tailored CV and Email feature</b>, you can generate
                        customized application materials specifically designed
                        for each supervisor, while also saving time and energy.
                        This ensures that your CV highlights the most relevant
                        aspects of your experience and research interests, while
                        the email you send is personalized to reflect your
                        knowledge of the supervisor’s work. By tailoring your
                        approach to each opportunity, you significantly enhance
                        your chances of catching the supervisor's attention and
                        securing a funded PhD position.
                      </p>
                    </li>
                  </ul>
                  <span className="primary-color font-weight-bold">
                    <i className="fal fa-check " style={{ minWidth: 25 }} />
                    How to Use “Supervisor Service” – A Step-by-Step Guide
                  </span>
                  <p>
                    Now, let’s see how you should get started with our
                    supervisor service and get the best results with it.
                  </p>
                  <div className="mt-20">
                    <h5 className="mb-10">1. Create a Profile on Kite</h5>
                    <p>
                      First, you need to set up a profile on our platform. In
                      your profile, you’ll include details such as your field of
                      study and upload your CV.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={300} src={profileImg} alt="supervisors" />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">
                      2. Go to “Supervisor” on the Left Menu
                    </h5>
                    <p>
                      After logging in, you’ll see the "Supervisor" section in
                      the left-hand menu. This page lists all supervisors in our
                      database, regardless of country, grant status, or
                      specified field.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={600} src={sidebarImg} alt="supervisors" />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">
                      3. Apply Filters for More Targeted Results
                    </h5>
                    <p>
                      Based on the information in your profile, Kite
                      automatically generates a list of keywords and research
                      interests relevant to your major. You can further optimize
                      this list by typing in specific keywords, selecting a
                      country, or adjusting other filters.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={1000} src={filterImg} alt="supervisors" />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">4. Investigate the Supervisor</h5>
                    <p>
                      Once you’ve found a potential match, you can dive deeper
                      into the supervisor’s field of study, academic research,
                      LinkedIn profile, grant information, and collaborators.
                      This information will help you determine if they’re the
                      right PhD supervisor for your academic journey.
                    </p>
                    <div className="d-flex flex-column align-items-center  justify-content-center pt-20 pb-30">
                      {' '}
                      <img
                        width={1000}
                        src={ssaImg}
                        alt="supervisors"
                        className="mb-20"
                      />
                      <img
                        width={1000}
                        src={collaboratorsImg}
                        alt="supervisors"
                      />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">5. Take Your Chance</h5>
                    <p>
                      With everything you need in front of you, the next step is
                      to reach out. Use our "Generate Email" and "Generate CV"
                      buttons to receive customized materials for contacting the
                      supervisor.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={1000} src={actionImg} alt="supervisors" />
                    </div>
                    <p className="mt-20">
                      Our platform is designed to give you a competitive edge in
                      the highly competitive world of PhD admissions. By
                      building strong connections with professors, you gain
                      access to invaluable guidance on the application process
                      and funding sources. This direct communication is key to
                      maximizing your chances of success and securing a position
                      in a top-tier program.
                    </p>
                    <p>
                      With our curated and extensive list of professors, along
                      with the latest information on their research and funding
                      status, you’re equipped with everything you need to make
                      informed decisions and confidently pursue your academic
                      goals. Kite currently has <b>7,500 users</b>, many of whom
                      have successfully used this platform to reach their
                      academic goals. You can start building those essential
                      connections too and take the first step toward securing a
                      funded PhD position with the help of our comprehensive
                      supervisor service.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
