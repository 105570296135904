import React from 'react';
import profileImg from '../../assets/images/positions/P1.webp';
import sidebarImg from '../../assets/images/positions/P2.webp';
import filterImg from '../../assets/images/positions/P3.webp';
import sppImg from '../../assets/images/positions/P4.webp';
import actionImg from '../../assets/images/positions/P5.webp';

function DetailsService() {
  return (
    <>
      <section className="appie-service-details-area pt-100 pb-100">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-4">
              <div className="service-details-sidebar mr-50">
                <div className="service-category-widget">
                  <ul>
                    <li>
                      <i className="fal fa-user-friends"></i>Over 1000 Daily
                      Positions
                    </li>
                    <li>
                      <i className="fal fa-tag"></i>Globally Available
                    </li>
                    <li>
                      <i className="fal fa-home"></i>Access to Related
                      Supervisor
                    </li>
                    <li>
                      <i className="fal fa-bookmark"></i>Personlised Email Guide
                    </li>
                    <li>
                      <i className="fal fa-globe"></i>Personlised CV Review
                    </li>
                  </ul>
                </div>
              </div>
              </div> */}
            {/* <div className="thumb">
                <img src={thumb} alt="" />
              </div> */}
            <div className="col-lg-12">
              <div className="service-details-content">
                {/* <div className="row align-items-center">
                  <div className="thumb col-lg-8 ">
                    <img width={600} src={thumb} alt="supervisors" />
                  </div>
                  <div className="col-lg-4">
                    <div className="service-details-sidebar ">
                      <div
                        className="service-category-widget"
                        style={{ marginBottom: 0 }}
                      >
                        <ul>
                          <li>
                            <i className="fal fa-user-friends"></i>Over 2.5M
                            Professors
                          </li>
                          <li>
                            <i className="fal fa-tag"></i>Globally Available
                          </li>
                          <li>
                            <i className="fal fa-home"></i>Access to Related
                            articles
                          </li>
                          <li>
                            <i className="fal fa-bookmark"></i>Distinguish Grant
                            Holders
                          </li>
                          <li>
                            <i className="fal fa-globe"></i>Access to Academic
                            Page
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="content">
                  {/* <h3 className="title">What we're Building</h3> */}
                  <p>
                    Navigating the search for PhD positions can be daunting, but
                    our Position Service streamlines the process by providing
                    access to <b>over 1,000 daily opportunities</b> sourced from
                    across the web—including university websites, job boards,
                    social networks, and even professors' personal pages. With
                    our platform, you'll enjoy limitless search capabilities,
                    making it easier than ever to find the perfect PhD
                    opportunity that aligns with your academic and career goals.
                  </p>
                  <span className="primary-color font-weight-bold">
                    <i className="fal fa-check " style={{ minWidth: 25 }} />
                    Why Our Position Service Stands Out
                  </span>
                  <p>
                    Our Position Service goes beyond traditional PhD searches by
                    tapping into sources that are often overlooked, including
                    <b> announcements on professors’ social media pages</b>.
                    These opportunities are hidden gems that many applicants
                    miss, giving you a significant edge in your application
                    process. Our AI-powered platform is designed to offer
                    exceptional benefits, which we'll explore below.
                  </p>
                  <ul className="mt-20">
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Thousands of PhD Opportunities Daily: Find Your
                        Perfect Fit with Ease
                      </h5>
                      <p>
                        Searching for the right PhD position can be
                        overwhelming, especially when opportunities are
                        scattered across various platforms. Our service
                        simplifies the process by curating{' '}
                        <b>over 1,000 PhD positions daily</b> from a wide array
                        of sources, all in one place. Whether you're seeking a
                        niche research area or a prestigious program, our
                        extensive database ensures you'll find the perfect fit
                        quickly and efficiently. With such a vast selection
                        updated daily, your chances of finding the ideal
                        opportunity are better than ever before.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Globally Accessible: Opportunities for Students from
                        Every Corner of the World
                      </h5>
                      <p>
                        Our platform is a global resource designed for{' '}
                        <b>students from all backgrounds and regions</b>. No
                        matter where you are or where you want to study, our
                        service connects you with positions and programs that
                        match your aspirations. Whether you're aiming to study
                        in North America, Europe, Asia, or beyond, our platform
                        ensures you have access to the best opportunities
                        worldwide, tailored to your academic background and
                        desired research path.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Personalized Email: Customized Communication for Every
                        Position
                      </h5>
                      <p>
                        Making a strong first impression is crucial when
                        applying for competitive PhD positions. Our AI-powered
                        platform takes the guesswork out of crafting the perfect
                        introduction by generating{' '}
                        <b>personalized emails tailored to each position</b>.
                        These emails reflect the specific requirements and
                        expectations of the program or supervisor, helping you
                        stand out as a well-prepared and motivated candidate.
                        With our tool, you can confidently reach out to
                        professors and admissions committees, knowing your
                        message is optimized for success.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Personalized CV: Highlight Your Strengths for Each PhD
                        Position
                      </h5>
                      <p>
                        A generic CV often doesn’t do justice to your unique
                        skills and experiences, especially when applying for
                        specialized PhD positions. Our platform allows you to
                        create{' '}
                        <b>
                          a personalized CV that highlights the most relevant
                          aspects of your background for each position
                        </b>{' '}
                        you're applying for. By tailoring your CV to match the
                        specific requirements of each opportunity, you increase
                        your chances of catching the attention of potential
                        supervisors and standing out in the competitive
                        selection process.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Personalized Statement of Purpose: Send the Perfect
                        SOP for Your Dream Position
                      </h5>
                      <p>
                        Your Statement of Purpose (SOP) is a{' '}
                        <b>critical component </b>
                        of your PhD application, and it needs to be as
                        compelling and targeted as possible. With our platform,
                        you can generate a personalized SOP specifically crafted
                        for the position you're interested in. By aligning your
                        academic goals, research interests, and personal
                        motivations with the expectations of the program, our
                        tool helps you create an SOP that not only reflects your
                        passion but also resonates with the admissions
                        committee, significantly enhancing your chances of
                        acceptance.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ All the Info You Need: Making Your Application Easy
                      </h5>
                      <p>
                        Applying for PhD positions can involve navigating a maze
                        of information and requirements. Our platform simplifies
                        this by providing you with{' '}
                        <b>all the related information and links</b> you need in
                        one convenient location. From direct access to
                        application portals and deadlines to detailed guidelines
                        on what each program expects, we ensure you have
                        everything at your fingertips, making the application
                        process smoother and less stressful.
                      </p>
                    </li>
                    <li className="mb-20">
                      <h5 className="mb-10">
                        ⦁ Instant Alerts: Be the First to Apply and Boost Your
                        Chances
                      </h5>
                      <p>
                        Timing is crucial in the PhD application process, and
                        being <b>one of the first to apply</b> can give you a
                        significant advantage. Our platform ensures you never
                        miss an opportunity by sending instant alerts as soon as
                        new PhD positions are posted. Whether the opportunity is
                        listed on a university website, a professor's page, or a
                        social network, you'll be notified immediately, allowing
                        you to act quickly and increase your chances of making a
                        strong impression on potential supervisors.
                      </p>
                    </li>
                  </ul>
                  <span className="primary-color font-weight-bold">
                    <i className="fal fa-check " style={{ minWidth: 25 }} />
                    How to Use “Position Service” – A Step-by-Step Guide
                  </span>
                  <p>
                    Now, it's time to experience these benefits for yourself.
                    Here’s how to make the most of Kite’s “Position Service”:
                  </p>
                  <div className="mt-20">
                    <h5 className="mb-10">1. Create a Profile on Kite</h5>
                    <p>
                      Begin by setting up your profile on our platform, where
                      you'll include details like your field of study and upload
                      your CV.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={300} src={profileImg} alt="supervisors" />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">
                      2. Go to “Positions” on the Left Menu
                    </h5>
                    <p>
                      After logging in, go to the "Positions" section in the
                      left-hand menu. This page lists all positions in our
                      database that match your field of study.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={600} src={sidebarImg} alt="supervisors" />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">
                      3. Apply Filters for More Targeted Results
                    </h5>
                    <p>
                      Based on the information in your profile, Kite
                      automatically makes a list of open positions suited to
                      you. You can optimize this list further by adding specific
                      keywords or using filters to find the most relevant
                      opportunities. By checking the “has supervisor” box and
                      selecting a location, you might just find your dream
                      position.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={1000} src={filterImg} alt="supervisors" />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">4. Investigate the Position</h5>
                    <p>
                      Once you’ve found a potential match, explore the
                      position’s details, including the deadline, links, and any
                      other information to ensure it's the right opportunity for
                      you. You can even see how well this position aligns with
                      your profile with the match percentage.
                    </p>
                    <div className="d-flex flex-column align-items-center  justify-content-center pt-20 pb-30">
                      {' '}
                      <img
                        width={1000}
                        src={sppImg}
                        alt="supervisors"
                        className="mb-20"
                      />
                    </div>
                  </div>
                  <div className="mt-20">
                    <h5 className="mb-10">5. Get Your Documents Ready</h5>
                    <p>
                      After confirming the position is the best fit, use our
                      tools to generate your Email, CV, and SOP tailored
                      specifically for this position. These features save you
                      time and ensure your application materials are perfectly
                      adjusted for each position.
                    </p>
                    <div className="d-flex justify-content-center pt-20 pb-30">
                      {' '}
                      <img width={1000} src={actionImg} alt="supervisors" />
                    </div>
                    <p className="mt-20">
                      With our Position Service, you’re equipped to stay ahead
                      in the competitive PhD application process. By offering
                      access <b>to over 1,000 new PhD opportunities daily</b>{' '}
                      and providing personalized tools{' '}
                      <b>like tailored CVs, SOPs, and emails</b>, we simplify
                      your search and application journey. Our instant alerts
                      ensure you’re among the first to apply, giving you a
                      crucial edge over other candidates.
                    </p>
                    <p>
                      Kite is proud to support <b>over 7,500 users</b> who are
                      successfully navigating their PhD hunt with our helpful
                      Position Service. By utilizing our platform, you join a
                      community of ambitious students who have turned these
                      opportunities into academic achievements. Start using our
                      Position Service today to experience a smooth applying
                      process and enhance your chances of securing your ideal
                      PhD position.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DetailsService;
