import React from 'react';
import IconOne from '../../assets/images/icon/1.gif';
import IconTwo from '../../assets/images/icon/2.gif';
import IconThree from '../../assets/images/icon/3.gif';
import IconFour from '../../assets/images/icon/4.gif';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                Kite provide you with <br /> imaginary smart services
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon" style={{ boxShadow: 'none' }}>
                                <img src={IconOne} alt="" />
                            </div>
                            <h4 className="appie-title">PhD Opportunities</h4>
                            <p>
                                Access to over <b>1000</b> daily announced positions in Twitter and
                                LinkedIn
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon" style={{ boxShadow: 'none' }}>
                                <img src={IconTwo} alt="" />
                            </div>
                            <h4 className="appie-title">Potential Supervisors</h4>
                            <p>
                                Access to over <b>2.5 Million</b> supervisors all around the world
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-3 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon" style={{ boxShadow: 'none' }}>
                                <img src={IconThree} alt="" />
                            </div>
                            <h4 className="appie-title">Find my Partner</h4>
                            <p>
                                A community of over <b>30K</b> Fully professional profiles to
                                partner with
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-4 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon" style={{ boxShadow: 'none' }}>
                                <img src={IconFour} alt="" />
                            </div>
                            <h4 className="appie-title">Daily Alerts</h4>
                            <p>Announce daily positions to users upon shared in social media</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
