import React from 'react';
import { Link } from 'react-router-dom';
import thumb from '../../assets/images/webapp/1.png';

function WebappOne({ className }) {
  return (
    <section className={`appie-traffic-area pt-140 pb-180 ${className || ''}`}>
      <div className="container d-md-flex align-items-center">
        <div className="row col-md-4">
          <div>
            <div className="appie-traffic-title " style={{ paddingRight: 20 }}>
              <span>Supervisors Section</span>
              <h3 className="title">Supervisors</h3>
              <p className="pr-md-20">
                Quickly find potential supervisors worldwide who match perfectly
                with your research interests and have recent grants and open
                positions.
                <span className="ml-2" style={{ fontSize: 16 }}>
                  <Link
                    className="primary-color font-weight-bold "
                    to="/supervisors"
                  >
                    Learn More
                  </Link>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-8  ">
          <div
            className="shadow-xl  overflow-hidden rounded-xl "
            style={{ padding: '5px 0 3px 1px' }}
          >
            <img
              width={1100}
              className="wow animated fadeInRight"
              data-wow-duration="2000ms"
              data-wow-delay="200ms"
              src={thumb}
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default WebappOne;
